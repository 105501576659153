<template>
  <IAMPage v-if="collaborator">
    <div>
      <SelectCollaboratorModal
        ref="selectCollaboratorModal"
        @select="addCollaborator"
      />
      <ActivityFeedbackModal
        ref="feedbackModal"
        :lead="lead"
        :entity="property"
        :activity="activity"
        :show-deal-button="false"
        :show-details-button="false"
        :show-break-button="showBreakButton"
        :show-finish-button="showFinishButton"
        @activity-finished="activityFinished"
      />

      <BaseModal ref="inviteModal" title="Collega's uitnodigen voor afspraak">
        <div class="tw-flex tw-flex-wrap tw-gap-4">
          <div
            v-for="collaborator in visibleCollaborators"
            :key="collaborator.id"
            class="tw-mb-4 tw-w-12 tw-h-12 tw-text-center"
          >
            <a title="Selecteer medewerker" @click="inviteCollaborator(collaborator)">
              <img
                :src="getPicture(collaborator)"
                :class="['img-circle', { 'active': inviteCollaborators.indexOf(collaborator) > -1 }]"
              >
            </a>
            {{ collaborator.first_name }}
          </div>
        </div>
      </BaseModal>

      <Panel
        title="Agenda"
        :is-collapsible="false"
      >
        <div slot="toolbar-buttons">
          <button
            title="Lead formulier tonen of verbergen"
            class="btn btn-primary btn-xs"
            @click="toggleLeadForm"
          >
            <i class="fa fa-calendar" />
          </button>
          <button
            title="Agenda's toevoegen aan het overzicht"
            class="btn btn-success btn-xs tw-mx-1"
            @click="showSelectCollaboratorModal"
          >
            <i class="fa fa-user" />
          </button>
          <button
            data-toggle="dropdown"
            aria-expanded="false"
            class="btn btn-xs btn-default dropdown-toggle"
          >
            Kantoor <span class="caret" />
          </button>
          <ul class="dropdown-menu pull-right">
            <li>
              <a
                v-for="office in offices"
                :key="office.id"
                @click="loadCollaboratorsForOffice(office)"
              >
                {{ office.display_name }}
              </a>
            </li>
          </ul>
        </div>
        <div v-show="visibleCollaborators.length" class="tw-ml-1 tw-mb-4">
          <VisibleCollaboratorItem
            v-for="collaborator in visibleCollaborators"
            :collaborator="collaborator"
            :key="collaborator.id"
            @deselect="removeCollaborator"
          />
        </div>
        <div>
          <div class="row">
            <FormulateForm
              #default="{ hasErrors }"
              v-show="showLeadForm"
              v-model="values"
              name="activityForm"
              class="col-lg-4"
            >
                <ActivityButtonGroup
                  ref="salesActivity"
                  @selected-activity-type-id="setSelectedActivityTypeId"
                />

                <div class="tw-my-4 tw-grid md:tw-grid-cols-2 tw-gap-4">
                  <FormulateInput
                    type="autocomplete"
                    auto-complete-type="contact"
                    name="contact"
                    label="Contact"
                    placeholder="Selecteer contact"
                    validation="bail|required|validContact"
                    :validation-rules="{ validContact }"
                    :validation-messages="{
                      validContact: 'De contactpersoon moet een geldig e-mailadres, telefoon- of mobiel nummer hebben'
                    }"
                    outer-class="tw-m-0"
                    @input="checkLeadExistence({ contact: $event })"
                  >
                    <template #label="{ label, id, classes }">
                      <div class="tw-flex tw-justify-between">
                        <label :for="id" :class="classes.label" v-text="label" class="formulate-label" />
                        <button
                          type="button"
                          class="link tw-font-semibold"
                          @click="showContactCreateModal"
                        >
                          <i class="far fa-user-plus" /> Nieuw
                        </button>
                      </div>
                    </template>
                  </FormulateInput>
                  <FormulateInput
                    type="autocomplete"
                    auto-complete-type="property"
                    name="property"
                    label="Pand"
                    placeholder="Selecteer pand"
                    validation="bail|required|validCity"
                    :validation-rules="{ validCity }"
                    :validation-messages="{
                      validCity: 'Het pand moet gelinkt zijn aan een gemeente.'
                    }"
                    outer-class="tw-m-0"
                    @input="propertySelectionChanged"
                  >
                    <template #label="{ label, id, classes }">
                      <div class="tw-flex tw-justify-between">
                        <label :for="id" :class="classes.label" v-text="label" class="formulate-label" />
                        <button
                          type="button"
                          class="link tw-font-semibold"
                          @click="showPropertyCreateModal"
                        >
                          <i class="far fa-file-plus" /> Nieuw
                        </button>
                      </div>
                    </template>
                  </FormulateInput>
                </div>

                <label class="formulate-label">Lead aanmaken voor</label>
                <div class="form-group">
                  <CollaboratorSelector
                    ref="collaboratorSelector"
                    :forCollaborators="visibleCollaborators"
                  />
                </div>
                <div v-show="visibleCollaborators.length" class="form-group" id="attendee-group">
                  <a @click="showInviteModal">Collega's uitnodigen voor afspraak</a>
                </div>

                <div v-if="existingLead" class="alert alert-warning">
                  Deze lead bestaat al en zal gebruikt worden voor deze activiteit.
                  <router-link :to="{ name: 'LeadDetails', params: { id: existingLead.id } }">
                    Details
                  </router-link>
                </div>

                <!-- spontanuous is a typo, but it was this way in the old code -->
                <FormulateInput
                  type="radio"
                  name="lead_type"
                  label="Leadinformatie"
                  :options="{
                    spontanuous: 'Spontane lead',
                    prospect: 'Prospectlead'
                  }"
                  value="prospect"
                  :input-class="['tw-my-1']"
                  element-class="tw-flex tw-flex-row tw-flex-wrap tw-gap-4"
                />
                <FormulateInput
                  type="textarea"
                  name="description"
                  placeholder="Deze omschrijving zal gebruikt worden als je de activiteit inplant"
                />

                <div class="tw-my-4">
                  <label class="formulate-label">Tijd</label>
                  <DateRangePicker
                    ref="dateRange"
                    :old-style="false"
                    @hook:mounted="setDefaultDate"
                    @dateUpdated="dateRangeUpdate"
                  />
                </div>

                <SMSEmailConfirmation
                  :activity-type-id="activityTypeId"
                  @data-updated="setSmsEmailConfirmationData"
                />
                <div>
                  <div class="tw-my-8 tw-grid md:tw-grid-cols-3 tw-gap-2">
                    <FormulateInput
                      type="submit"
                      :disabled="creatingActivity"
                      :input-class="['tw-bg-primary tw-w-full']"
                      outer-class="tw-my-0"
                      @click="!hasErrors && createLeadActivity('unfinished')"
                    >
                      Inplannen
                    </FormulateInput>
                    <FormulateInput
                      type="submit"
                      :disabled="creatingActivity"
                      :input-class="['tw-bg-success tw-w-full']"
                      outer-class="tw-my-0"
                      @click="!hasErrors && createLeadActivity('finished')"
                    >
                      Afpunten
                    </FormulateInput>
                    <FormulateInput
                      type="submit"
                      :disabled="creatingActivity"
                      :input-class="['tw-bg-danger tw-w-full']"
                      outer-class="tw-my-0"
                      @click="!hasErrors && createLeadActivity('break')"
                    >
                      Breaken
                    </FormulateInput>
                  </div>

                  <div class="tw-text-error">
                    <ul v-if="invalidFormMessages.length">
                      <li
                        v-for="(invalidFormMessage, index) in invalidFormMessages"
                        :key="index"
                      >
                        {{ invalidFormMessage }}
                      </li>
                    </ul>
                  </div>
                </div>
            </FormulateForm>
            <div :class="[showLeadForm ? 'col-lg-8' : 'col-lg-12']">
              <IAMPlanner
                ref="planner"
                @dateUpdated="dateRangeUpdate"
                @ready="loadCollaboratorsFromLocalStorage"
              />
            </div>
          </div>
        </div>
      </Panel>
    </div>
    <ContactCreateModal
      ref="contactCreateModal"
      :redirect="false"
      @success="newContactCreated"
    />
    <PropertyCreateModalCompact
      ref="propertyCreateModal"
      @success="newPropertyCreated"
    />
    <LeadBreakHmodhFormOpener ref="hmodhOpener" />
  </IAMPage>
</template>

<script>
import moment from 'moment'

import IAMPage from '@/components/iam/Page'
import IAMPlanner from '@/components/planner/iam-planner'
import SelectCollaboratorModal from '@/components/organization/SelectCollaboratorModal'
import VisibleCollaboratorItem from '@/components/planner/VisibleCollaboratorItem'
import ActivityButtonGroup from '@/components/sales/ActivityButtonGroup'
import ActivityFeedbackModal from '@/components/sales/ActivityFeedbackModal'
import DateRangePicker from '@/components/iam/DateRangePicker'
import CollaboratorSelector from '@/components/organization/CollaboratorSelector'
import SMSEmailConfirmation from '@/components/sales/SMSEmailConfirmation'
import Panel from '@/components/iam/Panel'
import ProfileImg from '@/assets/img/profile.png'
import LeadBreakHmodhFormOpener from '@/components/sales/LeadBreakHmodhFormOpener'
import ContactCreateModal from '@/components/contacts/ContactCreateModal'
import PropertyCreateModalCompact from '@/components/properties/PropertyCreateModalCompact'

import { getOfficeCollaborators } from '@/services/organization'
import { updatePropertyOwners } from '@/services/properties'
import { getUserOffices } from '@/services/user'
import { createActivity } from '@/services/sales'

import { mapActions, mapGetters } from 'vuex'
import { successModal, errorModal } from '@/modalMessages'

export default {
  name: 'Planner',
  components: {
    Panel,
    CollaboratorSelector,
    DateRangePicker,
    IAMPage,
    SelectCollaboratorModal,
    ActivityFeedbackModal,
    IAMPlanner,
    VisibleCollaboratorItem,
    ActivityButtonGroup,
    ContactCreateModal,
    SMSEmailConfirmation,
    PropertyCreateModalCompact,
    LeadBreakHmodhFormOpener
  },
  data () {
    return {
      values: {},
      activityTypeId: null,
      inviteCollaborators: [],
      initialCollaborators: [],
      visibleCollaborators: [],
      showLeadForm: true,
      existingLead: null,
      existingLeadUrl: '',
      invalidFormMessages: [],
      showBreakButton: false,
      showFinishButton: false,
      activity: null,
      lead: null,
      property: null,
      offices: [],
      smsEmailConfirmationData: null,
      creatingActivity: false,
      propertyCreatedId: null
    }
  },
  computed: {
    ...mapGetters('account', ['collaborator'])
  },
  created () {
    this.loadUserOffices()
  },
  methods: {
    ...mapActions('properties', ['loadPropertyCollaborators']),
    ...mapActions('sales', [
      'createLead',
      'checkExistingLead'
    ]),

    showContactCreateModal () {
      this.$refs.contactCreateModal.show()
    },
    showPropertyCreateModal () {
      this.$refs.propertyCreateModal.show()
    },
    showInviteModal () {
      this.$refs.inviteModal.show()
    },
    setSelectedActivityTypeId (activityTypeId) {
      this.activityTypeId = activityTypeId
    },
    setSmsEmailConfirmationData (values) {
      this.smsEmailConfirmationData = values
    },
    newPropertyCreated (property) {
      this.$set(this.values, 'property', property)
      // Id of the property is set to define whether an owner should be created or not
      this.propertyCreatedId = property.id
      return property
    },
    newContactCreated (contact) {
      const { type, company_name, first_name, last_name } = contact
      const display_name = type === 'B' ? company_name : `${first_name} ${last_name}`
      this.$set(this.values, 'contact', { display_name, ...contact })
      return contact
    },

    validContact ({ value }) {
      // At-least one contact info is required
      return !!(value.mobile || value.phone || value.email)
    },
    validCity ({ value }) {
      // Should have valid city
      return !!value.city
    },

    getPicture (collaborator) {
      return collaborator.picture || ProfileImg
    },
    setDefaultDate () {
      this.$refs.dateRange.setStartDate(moment())
      this.$refs.dateRange.setEndDate(moment().add(30, 'minutes'))
    },
    dateRangeUpdate (event) {
      const source = event.source
      const startDate = event.startDate
      const endDate = event.endDate
      const dateRangePicker = this.$refs.dateRange
      const planner = this.$refs.planner
      if (source === 'dateRangePicker') {
        planner.setEvent(startDate, endDate)
      } else if (source === 'planner') {
        dateRangePicker.setStartDate(startDate)
        dateRangePicker.setEndDate(endDate)
      } else {
        console.error('Invalid source specified')
      }
    },
    toggleLeadForm () {
      this.showLeadForm = !this.showLeadForm
    },
    /**
     Called when the ActivityFeedbackModal has saved the feedback and marked the activity as finished.
     */
    activityFinished (activity, lead) {
      this.$refs.feedbackModal.hide()
      this.$refs.planner.refresh()
      this.clear()
      if (this.extraCallback) {
        this.extraCallback()
      }
      this.$refs.hmodhOpener.trigger(lead.id)
    },
    clear () {
      this.$formulate.reset('activityForm')
      this.restoreCollaborators()
    },
    storeCollaborators () {
      const collabs = []
      this.visibleCollaborators.forEach(collab => collabs.push(collab))
      this.initialCollaborators = []
      collabs.forEach(collab => {
        this.initialCollaborators.push(collab)
        this.removeCollaborator(collab)
      })
    },
    restoreCollaborators () {
      const collabs = []
      this.visibleCollaborators.forEach(collab => collabs.push(collab))
      collabs.forEach(collab => {
        this.removeCollaborator(collab)
      })
      this.initialCollaborators.forEach(collab => {
        this.addCollaborator(collab)
      })
    },

    /** This method adds a collaborator to the visible collaborators.
     @param {obj} collaborator: an object holding the collaborator data for the collaborator to be added
     */
    addCollaborator (collaborator) {
      if (this.visibleCollaborators.indexOf(collaborator) > -1) return
      this.visibleCollaborators.push(collaborator)
      this.$refs.planner.addCollaborator(collaborator)
      this.buildCollaboratorLocalStorage()
      this.addCollaboratorToLocalStorage(collaborator)
    },

    /** On page reload make sure the collaborator for which his/her agenda is to be shown is loaded
     by adding the collaborator to local storage.
     @param {obj} collaborator: An object holding the data for the collaborator to be added
     */
    addCollaboratorToLocalStorage (collaborator) {
      const invitedCollaborators = JSON.parse(localStorage.getItem('invitedCollaborators'))
      invitedCollaborators[collaborator.id] = collaborator
      localStorage.setItem('invitedCollaborators', JSON.stringify(invitedCollaborators))
    },

    /** On page reload make sure the collaborator for which his/her agenda is not to be shown is not loaded
     by removing the collaborator from local storage
     @param {obj} collaboratorId: An object holding the data for the collaborator to be removed
     */
    removeCollaboratorFromLocalStorage (collaboratorId) {
      const invitedCollaborators = JSON.parse(localStorage.getItem('invitedCollaborators'))
      delete invitedCollaborators[collaboratorId]
      localStorage.setItem('invitedCollaborators', JSON.stringify(invitedCollaborators))
    },
    /** Check if local storage for collaborators exists if not build it. */
    buildCollaboratorLocalStorage () {
      if (!localStorage.getItem('invitedCollaborators')) {
        localStorage.setItem('invitedCollaborators', JSON.stringify({}))
      }
    },
    /** Loads all collaborators from local storage */
    loadCollaboratorsFromLocalStorage () {
      let invitedCollaborators = JSON.parse(localStorage.getItem('invitedCollaborators'))
      if (invitedCollaborators) {
        invitedCollaborators = Object.values(invitedCollaborators)
        this.visibleCollaborators = this.visibleCollaborators.concat(invitedCollaborators)
        this.visibleCollaborators.forEach(visibleCollaborator => this.$refs.planner.addCollaborator(visibleCollaborator))
      }
    },
    /** This method invites a collaborator.
     @param {obj} collaborator: An object holding the data for the collaborator to be invited
     */
    inviteCollaborator (collaborator) {
      if (this.inviteCollaborators.indexOf(collaborator) === -1) {
        this.inviteCollaborators.push(collaborator)
      } else {
        const idx = this.inviteCollaborators.indexOf(collaborator)
        if (idx > -1) this.inviteCollaborators.splice(idx, 1)
      }
    },
    /** This method shows the collaborator modal. */
    showSelectCollaboratorModal () {
      this.$refs.selectCollaboratorModal.show()
    },

    /** This method deletes a collaborator from the agenda.
     @param {obj} collaborator: An object holding the data for the collaborator to be removed from the agenda
     */
    removeCollaborator (collaborator) {
      const idx = this.visibleCollaborators.indexOf(collaborator)
      if (idx > -1) {
        this.visibleCollaborators.splice(idx, 1)
        this.$refs.planner.removeCollaborator(collaborator)
        this.removeCollaboratorFromLocalStorage(collaborator.id)
      }
    },

    createActivityPayload (leadData, finished, description) {
      const activityType = this.$refs.salesActivity.getSelectedActivity()
      const name = `${activityType.name} | ${leadData.name}`
      const eventTime = this.$refs.planner.newEvent

      let start_date = null
      let end_date = null
      if (eventTime.start && eventTime.end) {
        start_date = eventTime.start.format().split('+')[0]
        end_date = eventTime.end.format().split('+')[0]
      }

      const status = finished ? 4 : 1

      const activityPayload = {
        name,
        finished,
        status,
        end_date,
        start_date,
        description,
        type: activityType.id,
        invites: this.inviteCollaborators.map(collaborator => collaborator.id)
      }
      if (!this.smsEmailConfirmationData?.confirmationApplies) return activityPayload

      const {
        sendEmailSmsReminder,
        sendEmailConfirmation,
        confirmationLocation,
        confirmationAddress
      } = this.smsEmailConfirmationData
      const confirmation = {
        send_email_sms_reminder: sendEmailSmsReminder,
        send_email_confirmation: sendEmailConfirmation,
        confirmation_location: confirmationLocation,
        confirmation_address: confirmationAddress
      }

      return { ...activityPayload, ...confirmation }
    },
    /**
     This method creates a lead with a scheduled activity.
     @param{bool} finishedActivity: If we are dealing with a lead for which its activity is immediately finished.
     */
    async createLeadActivity (activityType) {
      try {
        const startDatetime = this.$refs.dateRange.getStartDateAsDatetime()
        const endDatetime = this.$refs.dateRange.getEndDateAsDatetime()
        if (startDatetime > endDatetime) {
          return errorModal('Einddatum moet na startdatum liggen')
        }

        this.creatingActivity = true
        let finishActivity = false

        switch (activityType) {
          case 'finished':
            finishActivity = true
            this.showBreakButton = false
            this.showFinishButton = true
            break
          case 'break':
            finishActivity = true
            this.showBreakButton = true
            this.showFinishButton = false
            break
          default:
            break
        }

        const { contact, property, lead_type, description } = this.values
        const leadPayload = {
          agent: this.$refs.collaboratorSelector.selectedCollaborator,
          spontanuous: lead_type === 'spontanuous',
          candidates: [contact.id],
          property: property.id,
          description
        }

        // Say the selected property changes after creating one, the selected contact should not be set as owner
        // Order of execution is important: first create a posible owner, then create lead + activity
        // Why? In case of intake, the owner is included in the Google Calendar event description
        if (this.propertyCreatedId === property.id) {
          const relation_ids = [contact.id]
          const invoicing_shares = [100.0]
          await updatePropertyOwners(property.id, { relation_ids, invoicing_shares })
        }

        const lead = this.existingLead || await this.createLead(leadPayload)
        const activityPayload = this.createActivityPayload(lead, finishActivity, description)
        const response = await createActivity(lead.id, activityPayload)

        // Create a callback that will be called as extraCallback
        const callback = async () => {
          this.$refs.planner.refresh()
          successModal('De lead en activiteit zijn aangemaakt')
          const leadId = lead.id
          const leadUrl = `/sales/lead/${leadId}`
          const routeData = this.$router.resolve(leadUrl)
          const win = window.open(routeData.href, '_blank')
          if (!win) errorModal('Sta pop-ups toe om de ondertekensessie te starten.')
        }

        if (finishActivity) {
          this.lead = lead
          this.property = property
          this.activity = response?.data
          this.$refs.feedbackModal.customCallback = callback
          this.$refs.feedbackModal.show()
        } else {
          callback()
        }
        this.clear()
        return response
      } catch (error) {
        console.log(error)
        let errorMessage = 'Kan lead niet aanmaken, gelieve opnieuw te proberen'
        if (error.responseJSON?.length) {
          errorMessage = `Kan lead niet aanmaken: ${error.responseJSON[0]}`
        }
        if (error.response?.data?.reference) {
          errorMessage = error.response?.data?.reference?.[0]
        }
        if (error.response?.data?.code?.[0] === 'ACTIVE_PROPERTY_EXISTS') {
          errorMessage = 'Er bestaat reeds een actief pand met dit adres. Gelieve dit pand te selecteren.'
        }
        errorModal(errorMessage)
      } finally {
        this.creatingActivity = false
      }
    },

    async loadUserOffices () {
      try {
        const params = { do_permission_check: 'false' }
        const response = await getUserOffices({ params })
        this.offices = response.data?.results
        return response
      } catch {
        errorModal('Fout bij het laden van kantoren voor de ingelogde gebruiker, probeer het opnieuw.')
      }
    },

    async loadCollaboratorsForOffice (office) {
      const response = await getOfficeCollaborators(office.id)
      const collaborators = response.data?.collaborators
      if (collaborators) collaborators.forEach(collab => this.addCollaborator(collab))
      this.storeCollaborators()
      return response
    },
    async checkLeadExistence ({ contact, property }) {
      const contact_id = (contact || this.values.contact)?.id
      const property_id = (property || this.values.property)?.id
      if (contact_id && property_id) {
        try {
          this.existingLead = await this.checkExistingLead({ contact_id, property_id })
        } catch (e) {
          const errorMessage = `Unable to validate if lead exists for contact: ${contact_id} and property ${property_id}`
          console.error(errorMessage)
        }
      } else {
        this.existingLead = null
        this.existingLeadUrl = ''
      }
    },
    /**
     * Called when the selected property has been changed
     * This function does the following
     *  - Load collaborators for the selected property
     *  - Check if there is an existing lead
     */
    async propertySelectionChanged (property) {
      if (property) {
        // Store curent selection of collaborators and load property collaborators
        this.storeCollaborators()
        this.loadPropertyCollaborators(property.id).then(collaborators => {
          collaborators.forEach(collab => {
            collab.id = collab.collaborator_id
            this.addCollaborator(collab)
          })
        })
      } else {
        this.restoreCollaborators()
      }
      return await this.checkLeadExistence({ property })
    }
  }
}
</script>

<style scoped>
  .active {
    border: 3px solid green;
  }
</style>
